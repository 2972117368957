import React from 'react'
import PropTypes from "prop-types"
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoAccueilClients from "../components/logoAccueilClients"
import Prestations from "../components/prestations"

const IndexPage = ({ data }) => (
  <Layout langKey={"en"}>
    <SEO
      title="Accueil - Ingénierie informatique à Besançon"
      description="Nos secteurs d'activité : transport et mobilité, banque et assurance, industrie, alimentaire et distribution, service public, médical"
      keywords={[`banque`, `assurance`, `service public`, `transport`, `mobilité`, `alimentaire`, `distribution`, `médical`, `industrie`]}
      jsonLd={[
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "Industries",
              item: "https://www.aprogsys.com/en/",
            },
          ],
        },
      ]}
    />
    <section className="accueil-banner hero" style={{position: 'relative'}}>
      <Img 
        fluid={data.placeholderImage.childImageSharp.fluid} 
        style={{width: '100%', height:'100%',position: 'absolute', top: '0', left: '0'}}
      />
      <div className="container hero-body">
        <div className="content hero-title has-text-centered">
          ­<div className="accueil-banner-content">
            <h1 className="is-size-3-touch"><span>Reveal all your <strong>skills</strong></span></h1>
            <div className="subtitle is-size-4-touch"><span>with our <strong>digital solutions</strong> adapted to <strong>your needs</strong></span></div>
          </div>
        </div>
        <div className="morecontent sub-hero-body is-hidden-touch">
          <Prestations data={data}/>
          <p className="title is-size-4-desktop is-size-4-touch has-text-centered">
            <span>Your need is our priority, our team is at your disposal</span>
          </p>
        </div>
      </div>
    </section>
    <section className="accueil-secteurs">
      <div className="container">
        <h2 className="is-size-4-touch has-text-primary has-text-centered">Industries</h2> 
        <div className="content accueil-secteurs-activite">
          <div>
            <div className="activite1-telephone">
              <svg style={{width:'100px', height:'100px'}}>
                <use xlinkHref="/img/activite-sprite.svg#icon-banque" />
              </svg>
            </div>
            <div className="activite1">
              <h3 className="is-size-5-touch">Banking and insurance</h3> 
              <ul>
                <li>Dashboards: optimization of your processes and statistics</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="activite2-telephone">
              <svg style={{width:'100px', height:'100px'}}>
                <use xlinkHref="/img/activite-sprite.svg#icon-industrie" />
              </svg>
            </div>
            <div className="activite2">
              <h3 className="is-size-5-touch">Industry</h3> 
              <ul>
                <li><strong>Tracking</strong> and production management</li>
                <li><strong>Workflow</strong> and productivity improvement</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="activite3-telephone">
              <svg style={{width:'100px', height:'100px'}}>
                <use xlinkHref="/img/activite-sprite.svg#icon-mairie" />
              </svg>
            </div>
            <div className="activite3">
              <h3 className="is-size-5-touch">Public service</h3> 
              <ul>
                <li>Document generation and management</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="activite4-telephone">
              <svg style={{width:'100px', height:'100px'}}>
                <use xlinkHref="/img/activite-sprite.svg#icon-hopital" />
              </svg>
            </div>
            <div className="activite4">
              <h3 className="is-size-5-touch">Medical</h3> 
              <ul>
                <li>Tracking</li>
                <li>Monitoring</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="activite5-telephone">
              <svg style={{width:'100px', height:'100px'}}>
                <use xlinkHref="/img/activite-sprite.svg#icon-parking" />
              </svg>
            </div>
            <div className="activite5">
              <h3 className="is-size-5-touch">Transport and mobility</h3> 
              <ul>
                <li>Ticketing solutions</li>
              </ul>
            </div>
          </div>
          <div>
            <div className="activite6-telephone">
              <svg style={{width:'100px', height:'100px'}}>
                <use xlinkHref="/img/activite-sprite.svg#icon-distribution" />
              </svg>
            </div>
            <div className="activite6">
              <h3 className="is-size-5-touch">Food and distribution</h3> 
              <ul>
                <li>Stock management</li>
                <li>Tracking</li>
                <li>E-commerce</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="accueil-partenaires">
      <div className="container">
        <h2 className="is-size-3-touch has-text-centered has-text-grey-dark">They trust us</h2>
        <LogoAccueilClients/>
        <p className="title is-size-3-touch has-text-centered has-text-grey-dark"><Link to="/fr/societe/#clients">Discovering others</Link></p>
      </div>
    </section>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.shape({
    placeholderImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default IndexPage

export const IndexPageQuery = graphql`
query {
  placeholderImage: file(relativePath: { eq: "FondBandeauPresentation.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "prestations" }, langKey: { eq: "en" } } }
    sort: { fields: [fileAbsolutePath], order: ASC }
  ) {
    totalCount
    edges {
      node {
        id
        html
        frontmatter {
          title
          svgFile { 
            childImageSharp {
              fluid(maxWidth: 100, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
}`