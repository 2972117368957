import React from "react"
import PropTypes from "prop-types"
//import Content, { HTMLContent } from "./Content"

export const PrestationTemplate = ({
  title,
  svgFile,
  //content,
  //contentComponent
}) => {
  //const PostContent = contentComponent || Content

  return (
    <div className="column">
      <h3 className="is-size-4-touch"><span>{ title }</span></h3>
    </div>
  )
}

const Prestations = (props) => {
  const { edges: prestations } = props.data.allMarkdownRemark

  return (
    <div className="columns is-variable is-8">
      {prestations &&
        prestations.map(({ node: prestation }, key) => (
          <PrestationTemplate
            title={prestation.frontmatter.title}
            svgFile={prestation.frontmatter.svgFile}
            //content={prestation.html}
            //contentComponent={HTMLContent}
            key={key}
          />
        ))
      }
    </div>
  )
}

Prestations.propTypes = {
  data: PropTypes.object
}

export default Prestations;