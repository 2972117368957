import React from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"

const logoEntreprises = ({ data }) => (
  <div className="columns is-multiline is-centered is-vcentered">
    {data && data.map(( entreprise, key ) => (
    <div className="column is-4" key={key} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
      <a href={entreprise.node.frontmatter.url} aria-label={entreprise.node.frontmatter.title}>
        {entreprise.node.frontmatter.logo && entreprise.node.frontmatter.logo.childImageSharp ? (
          <Img
            fluid={entreprise.node.frontmatter.logo.childImageSharp.fluid}
            style={{ maxWidth: 250, margin: "0 auto" }}
            imgStyle={{ objectFit: "contain" }}
            alt={entreprise.node.frontmatter.title}
          />
        ) : null }
      </a>
    </div>
    ))}
  </div>
)

logoEntreprises.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  ),
}

export default logoEntreprises